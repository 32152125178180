.container {
    background-color: lightgrey;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    margin: auto;
    width: 10vw;
    height: 10vw;
}